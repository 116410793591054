<template>
  <v-container class="max-container points-history-container">
    <v-card class="pa-3 card-box" flat dark color="black">
      <div class="desc-space" />
      <v-select
        v-model="selectValue"
        :items="items"
        label="view by year"
        dense
        @change="selectChange"
      />
      <v-list two-line>
        <v-list-item
          v-for="item in historyData"
          :key="item.create_time"
        >
          <v-list-item-avatar min-width="30">
            <img
              src="@/assets/coin.png"
              alt="coin"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-wrap" v-html="item.des" />
            <v-list-item-subtitle class="text-wrap caption" v-text="item.time_date" />
          </v-list-item-content>
          <!-- <v-list-item-action>
            <span>{{ item.score }}</span>
          </v-list-item-action> -->
        </v-list-item>
      </v-list>
    </v-card>
    <over-lay-loading ref="overlayloading" />
  </v-container>
</template>

<script>
import { getPointsHistory } from '@/api/user'
import OverLayLoading from './components/overLayLoading'

export default {
  name: 'PointsHistory',
  components: {
    OverLayLoading
  },
  data() {
    return {
      selectValue: new Date().getFullYear(),
      items: [2020, 2021, 2022],
      historyData: [],
      user: {}
    }
  },
  created() {
    this.$nextTick(() => {
      this.getPointsHistory()
    })
  },
  methods: {
    getPointsHistory() {
      this.$refs.overlayloading.controlShow(true)
      getPointsHistory({ 'year': this.selectValue, 'page': 1, 'pagesize': 100 }).then(res => {
        if (res.data.list) {
          this.historyData = res.data.list
          return
        }
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    selectChange() {
      this.getPointsHistory()
    }
  }
}
</script>

<style lang="scss" scoped>
.points-history-container{
    max-width: 370px!important;
}

.card-box{
    .desc-title-box{
        width:100%;
        text-align: center;
    }
    .desc-space{
        height: 30px;
    }
}

</style>
