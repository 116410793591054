<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
      <br><br>Processing
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'OverLayLoading',
  data() {
    return {
      overlay: false
    }
  },
  methods: {
    controlShow(overlay) {
      this.overlay = overlay
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
